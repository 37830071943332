<template>
  <v-container class="dashboard">
    <v-row>
      <v-col v-for="(item, index) in flattenedDashboardItems" :key="index" cols="12" sm="6" md="4">
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            class="dashboard-item"
            :elevation="isHovering ? 8 : 2"
            v-bind="props"
            @click="navigateTo(item.route)"
          >
            <v-card-title class="d-flex align-center">
              <v-icon size="large" :color="item.color" class="mr-4">{{ item.icon }}</v-icon>
              <span class="text-h5">{{ item.title }}</span>
            </v-card-title>
            <v-card-text>{{ item.description }}</v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { dashboardItems } from '@/navigationConfig';

const router = useRouter();

const flattenedDashboardItems = computed(() => {
  return dashboardItems.flatMap(item => {
    if (item.children && item.children.length > 0) {
      return item.children.map(child => ({
        ...child,
        description: child.description || item.description // Use parent description if child doesn't have one
      }));
    }
    return item;
  });
});

const navigateTo = (route) => {
  router.push(route);
};
</script>

<style scoped>
.dashboard-item {
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;
}
</style>
