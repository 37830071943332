import { createStore } from 'vuex'
import apiClient from '../api/apiClient';

// Helper function to safely parse JSON
function safeJSONParse(item, fallback) {
  if (!item) return fallback;
  try {
    return JSON.parse(item);
  } catch (error) {
    console.error('Error parsing JSON from localStorage:', error);
    return fallback;
  }
}

export default createStore({
  state: {
    token: localStorage.getItem('token') || null,
    user: safeJSONParse(localStorage.getItem('user'), null),
    permissions: safeJSONParse(localStorage.getItem('permissions'), []),
    loading: false,
  },
  getters: {
    isLoggedIn: state => !!state.token,
    hasPermission: state => permission => state.permissions.includes(permission),
    currentPermissions: state => state.permissions,
    currentUser: state => state.user,
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    clearToken(state) {
      state.token = null
      localStorage.removeItem('token')
    },
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
      localStorage.setItem('permissions', JSON.stringify(permissions))
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      const response = await apiClient.post('/api/auth/login', credentials)
      const { token, user } = response.data
      commit('setToken', token)
      commit('setUser', user)
      commit('setPermissions', user.permissions || [])
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    logout({ commit }) {
      commit('clearToken')
      commit('setUser', null)
      commit('setPermissions', [])
      localStorage.removeItem('user')
      localStorage.removeItem('permissions')
      delete apiClient.defaults.headers.common['Authorization']
    },
    async register({ commit }, credentials) {    
      const response = await apiClient.post('/api/auth/register', credentials);
      console.log('Register response:', response.data);
      const { token, user } = response.data;
      commit('setToken', token);
      commit('setUser', user);
      //commit('setPermissions', user.permissions || []);
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return response.data;
      
    },
    async fetchUserData({ commit, state }) {
      commit('setLoading', true);
      try {
        if (state.token) {
          // For now, just use the data from localStorage
          // When the server endpoint is ready, replace this with an API call
          const user = safeJSONParse(localStorage.getItem('user'), null)
          const permissions = safeJSONParse(localStorage.getItem('permissions'), [])
          if (user) {
            commit('setUser', user)
            commit('setPermissions', permissions || [])
          } else {
            // If no user data in localStorage, log out
            commit('clearToken')
            commit('setUser', null)
            commit('setPermissions', [])
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        commit('clearToken')
        commit('setUser', null)
        commit('setPermissions', [])
      } finally {
        commit('setLoading', false)
      }
    },
    async setNewPassword(_, payload) {
      try {
        console.log('Store: Setting new password with token:', payload.token);
        const response = await apiClient.post(`/api/auth/set-new-password/${payload.token}`, {
          newPassword: payload.newPassword
        });
        console.log('Store: Password reset response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Store: Password reset error:', error);
        throw error;
      }
    },
  },
})
