<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card class="mx-auto" max-width="400">
          <v-card-title>
            <span class="headline">Login</span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="email"
                label="Email"
                type="email"                
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" block>Login</v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <router-link to="/register">Don't have an account? Register</router-link>
              </v-col>
              <v-col cols="12" class="text-center">
                <router-link to="/reset-password">Forgot your password?</router-link>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from "vue-toastification";

export default {
  name: 'Login',
  setup() {
    const email = ref('');
    const password = ref('');
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const login = async () => {
      try {
        await store.dispatch('login', { email: email.value, password: password.value });
        toast.success('Login successful');
        router.push('/'); // This should navigate to the root route
      } catch (error) {
        console.error('Login failed:', error);
        let errorMessage = 'An unexpected error occurred. Please try again.';
        
        if (error.response) {
          switch (error.response.status) {
            case 401:
              errorMessage = 'Invalid email or password. Please try again.';
              break;
            case 404:
              errorMessage = 'User not found. Please check your email or register a new account.';
              break;
            case 500:
              errorMessage = 'Server error. Please try again later.';
              break;
          }
        }
        
        toast.error(errorMessage);
      }
    };

    return {
      email,
      password,
      login,
    };
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 100px;
}
</style>
