<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title>Reset Password</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="resetPassword">
              <v-text-field
                v-model="email"
                label="Email"
                type="email"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" block>Reset Password</v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <router-link to="/login">Back to Login</router-link>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from "vue-toastification";
import { requestPasswordReset } from '@/api/apiClient'; // Import the new function

export default {
  name: 'ResetPassword',
  setup() {
    const email = ref('');
    const router = useRouter();
    const toast = useToast();

    const resetPassword = async () => {
      try {
        await requestPasswordReset(email.value);
        toast.success("Password reset instructions sent to your email.");
        router.push('/login');
      } catch (error) {
        console.error('Password reset failed:', error);
        toast.error("Password reset failed. Please try again.");
      }
    };

    return {
      email,
      resetPassword,
    };
  },
};
</script>
