export const dashboardItems = [
  {
    title: 'Finance',    
    description: 'Upload and approve invoices. Reconcile and x-charge logistics They can be set to automatically be created in Xero and also update cost prices in the stock management system',
    route: '/',
    icon: 'mdi-file-document-outline',
    color: 'blue',
    children: [
      { title: 'Upload Invoices', component: 'UploadInvoices', route: '/upload-invoices', icon: 'mdi-file-upload', color: 'blue', description: 'Upload invoices from suppliers and automatically create in Xero' },
      { title: 'Invoice Management', component: 'InvoiceManagement', route: '/invoice-management', icon: 'mdi-file-document-multiple', color: 'blue', description: 'Manage invoices, update cost prices and automatically create in Xero' },
      { title: 'Wholesale Orders', component: 'WholesaleOrders', route: '/wholesale-orders', icon: 'mdi-point-of-sale', color: 'blue', description: 'Manage wholesale orders and automatically create in Xero' },
      { title: 'Purchase Orders', component: 'PurchaseOrders', description: 'Download and send purchase orders to suppliers', route: '/purchase-orders', icon: 'mdi-cart-outline', color: 'blue' },      
      { title: 'Logistics Reconciliation', component: 'LogisticsReconciliation', route: '/logistics-reconciliation', icon: 'mdi-truck', color: 'blue', description: 'Reconcile shipping costs across entities and x-charge invoices in Xero' }
    ]
  },
  {
    title: 'Bills of Materials',
    description: 'Manage bills of materials for manufacturing products. This includes the ability to create a new bill of material or update existing ones',
    route: '/',
    icon: 'mdi-clipboard-list-outline',
    color: 'green',
    children: [
      { title: 'BOM Management', component: 'BOM', route: '/bom', icon: 'mdi-clipboard-list-outline', color: 'green', description: 'Manage bills of materials for manufacturing products' },
      { title: 'Manufacturing', component: 'Manufacturing', route: '/manufacturing', icon: 'mdi-factory', color: 'green', description: 'Manage manufacturing orders. Create finished goods from raw materials' }
    ]
  },
  {
    title: 'Inventory',
    description: 'Manage stock takes and batch codes',
    route: '/',
    icon: 'mdi-package-variant-closed',
    color: 'orange',
    children: [
      { title: 'Stock Take', component: 'StockTakeManagement', route: '/stock-take', icon: 'mdi-scale-balance', color: 'orange', description: 'Manage stock takes for the warehouse' },
      { title: 'Stock Movement Report', component: 'StockMovementReports', route: '/stock-movement-reports', icon: 'mdi-transfer', color: 'orange', description: 'Track and analyze stock movements across locations' },
      { title: 'Batch Codes', component: 'BatchCodes', route: '/batch-codes', icon: 'mdi-barcode', color: 'orange', description: 'Manage batch codes for stock control' },
      { title: 'SKU Codes', component: 'GenerateSKU', description: 'Generate approved SKU codes for new products', route: '/sku-codes', icon: 'mdi-tag-outline', color: 'orange' },
      { title: 'Inventory Snapshot', component: 'InventorySnapshot', route: '/inventory-snapshot', icon: 'mdi-chart-line', color: 'orange', description: 'View historical inventory snapshots' }
    ]
  },
  {
    title: 'Product Management',
    description: 'Manage products and categories',
    route: '/',
    icon: 'mdi-tag-multiple',
    color: 'purple',
    children: [
      { title: 'Data Cleanser', component: 'CleanseProducts', route: '/cleanse-products', icon: 'mdi-broom', color: 'purple', description: 'Cleanse and improve product data in bulk' }
    ]
  },
  {
    title: 'Clinic & Dispensary',
    description: 'Reports and dispensary management',
    route: '/',
    icon: 'mdi-stethoscope',
    color: 'red',
    children: [
      { title: 'Practitioner Management', component: 'Practitioners', route: '/practitioner-management', icon: 'mdi-doctor', color: 'red', description: 'Manage practitioners and their commissions' },      
      { title: 'Dispensary Management', component: 'DispensaryManagement', route: '/dispensary-management', icon: 'mdi-pill', color: 'red', description: 'Manage dispensary stock and orders' }
    ]
  }, 
  {
    title: 'AI Insights',   
    component: 'AIInsights',
    description: 'Generate insights for stock, ordering and pricing with AI insights',
    route: '/ai-insights',
    icon: 'mdi-robot-outline',
    color: 'pink'
  },
  {
    title: 'Configuration',    
    description: 'System configuration and settings',
    route: '/',
    icon: 'mdi-cog',
    color: 'grey',
    children: [
      { title: 'General Settings', component: 'Config', route: '/config', icon: 'mdi-cog', color: 'grey', description: 'System configuration and settings' },
      { title: 'AI Assitants', component: 'AIConfig', route: '/ai-config', icon: 'mdi-robot-industrial', color: 'grey', description: 'AI Assistants configuration' },
      { title: 'User Management', component: 'UserManagement', route: '/user-management', icon: 'mdi-account-cog', color: 'grey', description: 'Manage users and permissions' }
    ]
  },
  // Add more items as needed
];

export const navigationItems = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    route: '/',
    color: 'primary'
  },
  ...dashboardItems,
  { title: 'Logout', icon: 'mdi-logout', route: null },
];

