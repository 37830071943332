<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title>Set New Password</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="setNewPassword" v-model="isFormValid">
              <v-text-field
                v-model="newPassword"
                label="New Password"
                type="password"
                :rules="passwordRules"
                required
              ></v-text-field>              
              <v-card outlined class="mb-4">
                <v-card-text>
                  <div v-for="(rule, index) in passwordRules" :key="index">
                    <v-icon :color="passwordMeetsRule(rule) ? 'green' : 'red'">
                      {{ passwordMeetsRule(rule) ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ getRuleMessage(rule) }}
                  </div>
                </v-card-text>
              </v-card>
              <v-text-field
                v-model="confirmPassword"
                label="Confirm New Password"
                type="password"
                :rules="[v => v === newPassword || 'Passwords must match']"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" block :disabled="!isFormValid">Set New Password</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from "vue-toastification";

export default {
  setup() {
    const newPassword = ref('');
    const confirmPassword = ref('');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const isFormValid = ref(false);

    const passwordRules = [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Password must be at least 8 characters',
      v => /[A-Z]/.test(v) || 'Must contain at least one uppercase letter',
      v => /[a-z]/.test(v) || 'Must contain at least one lowercase letter',
      v => /[0-9]/.test(v) || 'Must contain at least one number',
      v => /[^A-Za-z0-9]/.test(v) || 'Must contain at least one special character',
    ];

    const passwordStrength = computed(() => {
      let strength = 0;
      if (newPassword.value.length >= 8) strength += 20;
      if (/[A-Z]/.test(newPassword.value)) strength += 20;
      if (/[a-z]/.test(newPassword.value)) strength += 20;
      if (/[0-9]/.test(newPassword.value)) strength += 20;
      if (/[^A-Za-z0-9]/.test(newPassword.value)) strength += 20;
      return strength;
    });

    const passwordStrengthColor = computed(() => {
      if (passwordStrength.value < 40) return 'red';
      if (passwordStrength.value < 70) return 'orange';
      return 'green';
    });

    const passwordMeetsRule = (rule) => {
      return rule(newPassword.value) === true;
    };

    const getRuleMessage = (rule) => {
      return rule('').replace('Must ', '');
    };

    const setNewPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        toast.error("Passwords do not match");
        return;
      }

      try {
        console.log('Sending password reset request:', {
          token: route.params.token,
          newPassword: newPassword.value
        });

        const response = await store.dispatch('setNewPassword', {
          token: route.params.token,
          newPassword: newPassword.value
        });
        
        console.log('Password reset response:', response);

        if (!response) {
          throw new Error('No response received from server');
        }

        await store.dispatch('login', {
          email: response.email,
          password: newPassword.value
        });

        toast.success("Password has been reset successfully and you've been logged in.");
        router.push('/');
      } catch (error) {
        console.error('Set new password error details:', error);
        if (error.response?.status === 400 && error.response?.data?.error === 'Invalid or expired token') {
          toast.error("Your password reset link has expired. Please request a new one.");
          router.push('/reset-password');
        } else {
          toast.error("Failed to set new password. Please try again.");
          router.push('/login');
        }
      }
    };

    return { 
      newPassword, 
      confirmPassword, 
      setNewPassword,
      isFormValid,
      passwordRules,
      passwordStrength,
      passwordStrengthColor,
      passwordMeetsRule,
      getRuleMessage
    };
  }
};
</script>
