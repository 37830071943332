<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title>Register</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="register" v-model="isFormValid">
              <v-text-field
                v-model="name"
                label="Name"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                type="email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                :rules="passwordRules"
                required
              ></v-text-field>              
              <v-card outlined class="mb-4">
                <v-card-text>
                  <div v-for="(rule, index) in passwordRules" :key="index">
                    <v-icon :color="passwordMeetsRule(rule) ? 'green' : 'red'">
                      {{ passwordMeetsRule(rule) ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ getRuleMessage(rule) }}
                  </div>
                </v-card-text>
              </v-card>
              <v-btn type="submit" color="primary" block :disabled="!isFormValid">Register</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from "vue-toastification";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const email = ref('');
    const password = ref('');
    const isFormValid = ref(false);
    const name = ref('');

    const emailRules = [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Email must be valid'
    ];

    const passwordRules = [
      v => !!v || 'Password is required',
      v => v.length >= 8 || 'Password must be at least 8 characters',
      v => /[A-Z]/.test(v) || 'Must contain at least one uppercase letter',
      v => /[a-z]/.test(v) || 'Must contain at least one lowercase letter',
      v => /[0-9]/.test(v) || 'Must contain at least one number',
      v => /[^A-Za-z0-9]/.test(v) || 'Must contain at least one special character',
    ];

    const passwordStrength = computed(() => {
      let strength = 0;
      if (password.value.length >= 8) strength += 20;
      if (/[A-Z]/.test(password.value)) strength += 20;
      if (/[a-z]/.test(password.value)) strength += 20;
      if (/[0-9]/.test(password.value)) strength += 20;
      if (/[^A-Za-z0-9]/.test(password.value)) strength += 20;
      return strength;
    });

    const passwordStrengthColor = computed(() => {
      if (passwordStrength.value < 40) return 'red';
      if (passwordStrength.value < 70) return 'orange';
      return 'green';
    });

    const passwordMeetsRule = (rule) => {
      return rule(password.value) === true;
    };

    const getRuleMessage = (rule) => {
      return rule('').replace('Must ', '');
    };

    const register = async () => {
      try {
        await store.dispatch('register', {
          email: email.value,
          password: password.value,
          name: name.value,
        });
        toast.success('Registration successful. An adminstrator will review your registration...');
        router.push('/login');
      } catch (error) {
        console.error('Registration failed:', error);
        toast.error('Registration failed: ' + (error.response?.data?.message || error.message));
      }
    };

    return {
      name,
      email,
      password,
      register,
      isFormValid,
      emailRules,
      passwordRules,
      passwordStrength,
      passwordStrengthColor,
      passwordMeetsRule,
      getRuleMessage,
    };
  },
};
</script>
